:root {
    --primary-color: #0E8AC0;
}
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import "primeicons/primeicons.css";
@import "primeng/resources/primeng.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

// import Quill styles in styles.scss

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400..700&display=swap');

body {
    font-size: 16px;
    line-height: 1.5;
    font-family: "Roboto", sans-serif;
    color: #000;
    font-weight: 400;
    overflow-x: hidden;
    background-color: #F8FDFF;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Lora", serif;
}

h1,
h2,
h3 {
    font-weight: 700;
}

.semi_bold {
    font-weight: 600;
}

a {
    color: #0E8AC0;
}

a,
button {
    transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    -webkit-transition: all .2s ease-out;
    text-decoration: none !important;
}

a:hover {
    text-decoration: none;
}

header .bg-white {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 10px 10px;
}

.card {
    border: 1px solid #DDF5FF;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(14, 138, 192, 0.07);
    background-color: #fff;
    font-size: 18px;

    .card-head {
        border-bottom: 1px solid #E3F7FF;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .card-title {
        font-family: 'Roboto', sans-serif;
        color: #0E8AC0;
        font-weight: 600;
        font-size: 22px;
        margin-bottom: 0;
    }
}

.border-primary {
    border-color: #0E8AC0 !important;
}

.threeDotsIco {
    width: 10px;
}

.p-calendar {
    display: flex;
}
// Nav Meu styling start
.navbar {
    padding-bottom: 1rem;
    padding-top: 1rem;

    .logo {
        width: 240px;
    }

    .nav-item {
        padding-right: 40px;
    }

    .nav-item .nav-link {
        text-transform: capitalize;
        font-size: 16px;
    }
}

.navbar.navbar-light .nav-link {
    color: #000;
    padding: 10px;
    font-weight: 500;
    position: relative;
}

.navbar.navbar-light .nav-link:hover,
.navbar.navbar-light .nav-link:focus {
    color: #D95999;
}

.navbar.navbar-light .active .nav-link {
    color: #D95999;

    &:before {
        position: absolute;
        background-color: #D95999;
        height: 5px;
        width: 100%;
        content: '';
        bottom: -29px;
        left: 0;
    }
}

.profile-dropdown {
    display: inline-block;
    position: relative;
    background: #ffffff;
    margin: auto;
    font-weight: bold;
    font-size: 1.3rem;
    border-radius: 3px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 3px 8px 5px 8px;
    min-width: 75px;
    min-height: 40px;

    i.fa {
        color: #000;
        font-size: 1rem;
        margin-top: 0;
        margin-left: 8px;
        position: relative;
        top: 3px;
        margin-right: 4px;
        right: -16px;
    }
}

.navbar-toggler {
    margin-left: 10px;
    position: relative;
    font-size: 12px;
    padding: 10px
}

// Nav Meu styling end 

// Buttons styling start
.btn {
    text-transform: capitalize;
    padding: 11px 30px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 500;
}

a.btn-primary {
    color: #fff !important;
}

a.btn-primary:hover {
    border: 1px solid #0d7fb1 !important;
}

.btn.btn-lg {
    padding: 12px 25px;
    font-size: 22px;
}

.btn {
    padding: 8px 20px;
}

.btn.btn-sm {
    padding: 5px 15px;
    font-size: 16px;
}

.btn.btn-pink {
    background-color: #D95999;
    color: #fff;

    &:hover {
        background-color: #b33a76;
    }
}

.btn.btn-custom:after {
    position: absolute;
    content: '';
    right: -70%;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #fff;
    opacity: 0;
    -webkit-transform: skewX(0);
    transform: skewX(0);
    z-index: -1;
    -webkit-transition: all 0.4s cubic-bezier(0.82, 1.65, 0.54, 1.53);
    transition: all 0.4s cubic-bezier(0.82, 1.65, 0.54, 1.53);
}

.btn.btn-custom:hover:after {
    opacity: 0.2;
    -webkit-transform: skewX(30deg);
    transform: skewX(30deg);
    right: -50%;
}

.btn-primary {
    background-color: #0E8AC0;
    border-color: #0E8AC0;

    &:hover {
        background-color: #0d7fb1;
        border-color: #0d7fb1;
    }
}

.btn-primary.disabled,
.btn-primary:disabled {
    background-color: #2aa5db;
    border-color: #2aa5db;
}



.btn-outline-primary {
    color: #0E8AC0;
    border-color: #0E8AC0;

    &:hover {
        background-color: #0E8AC0;
        border-color: #0E8AC0;
    }
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    background-color: #0A648C;
    border-color: #0A648C;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}

.btn-icon {
    display: inline-flex;
    align-items: center;

    img {
        vertical-align: text-top;
    }

    >span:first-child {
        margin-right: 5px;
        margin-bottom: 0;
    }
}

// Buttons styling end
// Popup styling start
.p-component-overlay {
    background-color: rgba(255, 255, 255, 0.5);
}

.p-dialog {
    font-family: "Roboto", sans-serif;
    border: 1px solid #C1E5F4;

    .p-dialog-header {
        background-color: #EFFAFF;
        border-color: #C1E5F4;
        justify-content: center;

        .p-dialog-title {
            font-weight: 700;
            font-size: 26px;
            color: #0E8AC0;
            font-family: "Roboto", sans-serif;
        }
    }

    .p-dialog-header-icons {
        position: absolute;
        right: 12px
    }

}

// Popup styling end

// Dopdown styling start
.p-dropdown {
    background: #ffffff;
    // border: 1px solid #ced4da;
    border-color: #DAF4FF;
    box-shadow: 0 0 6px #DCF3FF inset;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    border-radius: 4px;
    width: 100%;
}

.p-inputtext {
    padding: 12px 15px;
}

// Dopdown styling start

.banner {
    margin-bottom: -20px;

    .banner-cont {
        background-size: cover;

        &::before {
            display: block;
            background: #000;
            opacity: 0.5;
            content: '';
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            position: absolute;
        }

        .banner-text {
            min-height: 750px;
            align-items: center;
            display: flex;

            .benner-sub {
                color: #fff;
                max-width: 465px;

                h1 {
                    font-weight: 700;
                    font-size: 45px;
                    margin-top: 0;
                    margin-bottom: 15px;
                    line-height: 50px;
                    text-transform: capitalize;
                }

                p {
                    font-size: 18px;
                }
            }
        }
    }

    .p-carousel .p-carousel-indicators {
        top: -50px;
        position: relative;
        padding: 0;
        margin-bottom: 0;
    }

    .p-carousel .p-carousel-indicators .p-carousel-indicator button {
        width: 12px;
        height: 12px;
        border-radius: 2px;
        border: 1px solid #2BAFE9;
        background-color: #fff;
    }

    .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
        background-color: #0E8AC0;
    }
}

// Buttons styling end

// Monthly Donation styling start
.offerSection {
    h2 {
        font-size: 70px;
    }

    p {
        margin: 20px 0 35px;
        max-width: 80%;
    }

    .startFundraiser {
        background: url(../../gc/public/images/startFundraiserBg.jpg) #fff right bottom no-repeat;

        div {
            padding: 20px 20px 30px;

            h2 {
                color: #D95999;
            }
        }
    }

    .donateMonthly {
        background-color: #D95999;
        color: #fff;

        div {
            padding: 20px 20px 30px;

            h2 span {
                font-family: "Roboto", sans-serif;
                font-weight: 300;
            }
        }
    }
}

// Monthly Donation styling start

// Number counter styling start
.fact-counter {
    background: url(../../gc/public/images/counterBg.png) #0E8AC0 repeat;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    color: #fff;

    .conterItem {
        padding: 60px 0 65px;

        span {
            font-size: 45px;
            font-weight: 700;
            font-family: "Lora", serif;
            margin-bottom: 0;
            display: block;
        }

        strong {
            font-size: 22px;
            font-weight: 500;
            display: block;
        }
    }
}

// Number counter styling start

// Home content styling start
.sectiontitle {
    text-align: center;
    padding: 60px 0 20px;

    h2 {
        font-size: 46px;
        margin-bottom: 10px;
    }

    p {
        margin: 0;
    }
}

.activeCampaign {
    background-color: #EEFAFF;
    padding: 30px 0 80px;

    .p-carousel-prev,
    .p-carousel-next {
        background-color: #fff;
        width: 70px;
        height: 60px;
        text-align: center;

        svg {
            width: 30px;
            height: 30px;

            path {
                fill: #D95999;
            }
        }
    }

    .p-carousel-item {
        padding: 0 15px;
    }

    .p-carousel-prev {
        border-radius: 5px 0 0 5px;
        margin-right: -15px;
    }

    .p-carousel-next {
        border-radius: 0 5px 5px 0;
        margin-left: -15px;
    }
}

.browseCampaigns {
    padding: 90px 0;
    background-color: #EEFAFF;

    .searchFilter {
        background: #fff;
        border: 1px solid #BBEAFF;
        padding: 15px;
        min-height: 75px;
        align-items: center;
        margin-bottom: 15px;
        border-radius: 5px;

        .input-group {
            border-right: 1px solid #000;
            margin-right: 10px;

            .form-control.box-input {
                border: solid 0px #dadada;
                box-shadow: none !important;
                padding: 8px 10px 8px 0;
            }

            .btn-search {
                background-color: transparent;
                border: 0;
                padding: 8px;
            }
        }

        .p-dropdown {
            border: 0px solid #ced4da;
        }

        .p-dropdown:not(.p-disabled).p-focus {
            box-shadow: none;
        }

    }

    .tab-section {
        .nav-tabs {
            border: 0;

            .nav-link {
                padding: 15px 20px;
            }

            .nav-link.active {
                background: #DFF5FF;
                color: #0E8AC0;

                &:after {
                    display: none;
                }
            }
        }
    }

    .p-dataview .p-dataview-content {
        background-color: transparent !important
    }

    .p-paginator {
        background: transparent;
        padding-top: 40px;

        button {
            width: 50px;
            height: 50px;
            border-radius: 5px;
            margin: 0 5px;

            &:focus {
                box-shadow: none !important;
                border: #000;
            }
        }

        .p-paginator-first,
        .p-paginator-prev,
        .p-paginator-next,
        .p-paginator-last {
            border: 1px solid #0E8AC0;
            color: #0E8AC0;
        }

        .p-paginator-pages .p-paginator-page.p-highlight {
            background: #0E8AC0;
            border-color: #0E8AC0;
        }
    }
}

.progress {
    position: relative;
    border-radius: 0;
    background: transparent;
    overflow: visible;
    margin-top: -6px;

    &:before {
        content: '';
        position: absolute;
        top: 6px;
        left: 0;
        background-color: #F2F2F2;
        height: 5px;
        width: 100%;
        z-index: 1;
    }

    .progress-bar {
        background-color: #D95999;
        height: 5px;
        margin-top: 6px;
        position: relative;
        z-index: 2;
    }

    .progress-number {
        border-radius: 10px;
        font-weight: 700;
        background-color: #D95999;
        color: #fff;
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 20px;
        padding-bottom: 1px;
        top: -2px;
        left: -1px;
    }
}

.campaignItem {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    height: 100%;
    position: relative;

    .cmapaignImg {
        height: 280px;
        position: relative;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        .campaignGoal {
            position: absolute;
            bottom: 20px;
            right: 0;
            background-color: rgba(255, 255, 255, 0.8);
            padding: 4px 10px 5px;
            border-radius: 5px 0 0 5px;
            font-size: 24px;
            font-weight: 700;
            color: #D95999;
        }
    }

    .campaignCont {
        padding: 25px 20px 95px;

        .cmapaignTitle {
            font-family: "Lora", serif;
            font-size: 22px;
            font-weight: 600;
        }

        .card-by {
            background-color: #EDEDED;
            display: inline-block;
            border-radius: 3px;
            text-transform: capitalize;
            font-size: small;
            padding: 3px 10px 5px;
        }

        p {
            margin: 10px 0 20px;
        }

        .cmapaignBottom {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 0 20px 20px;

            .daysLeft {
                font-size: 18px;
                font-weight: 600;
                color: #D95999;
                display: flex;
                align-items: center;

                span {
                    font-size: 26px;
                }
            }
        }
    }
}

.completedCampaign {
    background-color: #F8F8F8;
    padding: 30px 0 80px;
}

// Home content styling end
.p-inputtext,
.form-control {
    border-color: #DAF4FF;
    box-shadow: 0 0 6px #DCF3FF inset;
    padding: 12px 15px;
    height: auto;
    color: #494949;
    font-weight: 500;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
}

select.form-control {
    padding: 13px 15px 14px;
}

.p-dropdown-panel {
    border-color: #E3F7FF;
    .p-dropdown-header {
        background: #F1FBFF;
        border-color: #E3F7FF;
    }

    .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
        color: #0E8AC0;
        background: #F5FCFF;
    }
}
// Login Panel styling start
.gc_login {
    padding-top: 100px;
    background: url(../public/images/loginBg.jpg) no-repeat right center;
    background-size: auto 100%;

    .form_container_login {
        max-width: 600px;
        margin: 60px auto;
        position: relative;
        z-index: 1;
    }

    .signUpCont {
        border-top: 3px solid #838383;
        margin-top: 20px;
        padding-top: 40px;

        p {
            margin: 0;
        }
    }

    .gc_login_footer {
        border-top: 1px solid #dedede;
        padding: 15px 0;
        font-size: 14px;
        color: #c2c2c2;
        margin: 50px 0 0;

        div {
            padding: 0;
        }

        .footerLoginNav {
            text-align: right;

            ul {
                list-style-type: none;
                margin: 0;

                li {
                    display: inline-block;

                    &:first-child {
                        border-right: 1px solid #c2c2c2;

                        a {
                            padding-right: 15px;
                        }
                    }

                    a {
                        color: #c2c2c2;
                        padding-left: 15px;

                        &:hover {
                            color: #000;
                        }
                    }
                }
            }

        }
    }
}

// Login Panel styling end
// Inner page banner start
.innerPageBanner {
    background: url(../../gc/public/images/headerBg.jpg) center center no-repeat;
    background-size: cover;
    padding-top: 100px;
    min-height: 29vw;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #DAF4FF;

    h1 {
        font-size: 46px;
        color: #0E8AC0;
    }

    p {
        margin-top: 0;
        font-size: 16px;
    }

    .text-center div {
        font-size: 24px;
    }
}

// Inner page banner end
// Save every month start
.saveEveryMonth {
    margin-top: -2.4vw;

    .container-lg>.row {
        background: rgb(217, 89, 153);
        background: linear-gradient(270deg, rgba(217, 89, 153, 1) 0%, rgba(115, 47, 81, 1) 100%);
        border-radius: 5px;
        color: #fff;
        padding: 45px;
        position: relative;
        align-items: center;
        margin: 0;

        &:after {
            // background: url(../../gc/public/images/saveMonthlyBG.png) right bottom no-repeat;
            content: url('../../gc/public/images/saveMonthlyBG.png');
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            pointer-events: none;
        }

        .saveText {
            font-size: 38px;
            font-weight: 300;

            span {
                font-size: 50px;
                font-family: "Lora", serif;
                font-weight: 700;
                display: block;
                margin-bottom: -15px;
            }
        }

        .btn-light {
            color: #D95999;
            margin-top: 25px;
        }

        .monthlyBenefit {
            list-style-type: none;
            padding: 0 120px 0 0;
            margin: 0px;
            font-weight: 700;
            font-size: 24px;

            li {
                padding-left: 35px;
                background: url(../../gc/public/images/monthlyCheck.png) left 10px no-repeat;
                margin-bottom: 20px;
            }
        }
    }
}

.monthlyDonate {
    margin-top: 102px;

    .container-lg {
        background: rgb(217, 89, 153);
        background: linear-gradient(270deg, rgba(217, 89, 153, 1) 0%, rgba(115, 47, 81, 1) 100%);
        border-radius: 0 0 5px 5px;
        color: #fff;
        padding: 15px 85px 15px 30px;
        position: relative;
        align-items: center;
        max-width: 90%;

        &:after {
            background: url('../../gc/public/images/saveMonthlyBG.png') right 5px no-repeat;
            background-size: auto 100%;
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
        }

        .saveText {
            font-size: 30px;
            font-weight: 300;
            line-height: 34px;

            span {
                font-family: "Lora", serif;
                font-weight: 700;
                margin-bottom: -15px;
            }
        }
    }
}

// Save every month end

// Campaign Detail start
.campaignDetails {
    margin-top: 50px;

    .campaignCarousel {
        .p-galleria .p-galleria-item-nav {
            background: rgba(222, 110, 166, 0.75);
            color: #fff;

            &:focus {
                outline: none;
            }
        }

        .p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
            background: rgba(222, 110, 166, 1);
        }

        .p-galleria .p-galleria-item-nav.p-galleria-item-prev {
            border-radius: 0 5px 5px 0;
        }

        .p-galleria .p-galleria-item-nav.p-galleria-item-next {
            border-radius: 5px 0 0 5px;
        }

        div[aria-roledescription="Slide"] {
            border-radius: 5px;
            overflow: hidden;
            margin-bottom: 10px;
        }

        .p-galleria .p-galleria-thumbnail-container {
            background-color: #fff;

            button.p-link {
                background: rgba(222, 110, 166, 0.75);
                color: #fff;

                &:focus {
                    outline: none;
                }

                &:hover {
                    background: rgba(222, 110, 166, 1);
                }
            }

            .p-galleria-thumbnail-prev {
                border-radius: 5px 0 0 5px;
            }

            .p-galleria-thumbnail-next {
                border-radius: 0 5px 5px 0;
            }

            button.p-link.p-disabled {
                display: none;
            }
        }
    }

    .amountRaised {
        font-size: 24px;
        font-weight: 600;
        color: #D95999;
        display: flex;
        align-items: center;

        span {
            font-size: 30px;
            padding: 0 5px
        }
    }

    .campaignDaysLeft {
        font-size: 24px;
        font-weight: 500;
        padding-left: 5px;

    }

    .shareCmapign {
        margin-top: 20px;
        background-color: #eee;
        display: flex;
        justify-content: space-between;
        padding: 20px;
        font-size: 22px;
        font-weight: 700;

        .shareIcon {
            a {
                margin-left: 15px;

                i {
                    font-size: 35px;
                }
            }

            .wa {
                color: #4cc75b;
            }

            .twt {
                color: #000;
            }

            .in {
                color: #1275B1;
            }

            .fb {
                color: #4460A0;
            }
        }
    }

    .tab-section {
        border-bottom: 1px solid #DAECF3;
        padding: 25px 0 20px;

        .nav-tabs {
            border-bottom: 2px solid #0E8AC0;
            padding-left: 6px;

            .nav-item {
                margin-bottom: 0;
            }

            .nav-link {
                padding: 12px 20px;
                font-size: 20px;
                color: #333;
                border: 1px solid #D2D2D2;
                border-bottom: 0;
                margin: 0 6px;
                font-weight: 700;
            }

            .nav-link.active {
                border: 1px solid #9DE1FF;
                border-bottom: 0;
                color: #0E8AC0;
                box-shadow: 0 0 12px #D0F1FF inset;

                &:after {
                    display: none;
                }
            }
        }
    }

    .campaignDocuments {
        padding: 30px 0;

        h3 {
            font-size: 30px;
            font-family: "Roboto", sans-serif;
            color: #0E8AC0;
            padding-bottom: 20px;
        }

        .campaignSub {
            display: flex;

            .img-container {
                margin-right: 15px;
                border-radius: 5px;
                position: relative;
                overflow: hidden;
                cursor: pointer;

                &:before {
                    background: rgba(0, 0, 0, 0.2);
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    content: '';
                    opacity: 0;
                    transition: all .15s linear .05s;
                }

                &:after {
                    background: url(../public/images/icon/zoomPlusIco.png) center no-repeat;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    content: '';
                    opacity: 0;
                    transition: all .15s linear .05s;
                }

                &:hover::before,
                &:hover::after {
                    opacity: 1;
                }
            }
        }
    }
}

.gap {
    gap: 10px;
}

.campaignSlider {}

.currentDonors,
.donateBox,
.beneficiaryDetail {
    border: 1px solid #DDF5FF;
    border-radius: 5px;
    box-shadow: 0 4px 4px rgba(242, 251, 255, 0.7);
    background-color: #fff;
    font-size: 18px;
    padding: 25px;

    h3 {
        font-size: 26px;
        font-family: "Roboto", sans-serif;
        color: #0E8AC0;
    }

    strong {
        font-size: 20px;
    }
}

.suggestestAmt {
    .sugAmt {
        color: #0E8AC0;
        background: #DDF5FF;
        border-radius: 5px;
        margin-right: 10px;
        padding: 2px 10px 1px;
        margin-top: 5px;
        font-size: 18px;
    }

}

.currentDonors {
    strong {
        font-size: 18px;
    }

    .campaigDonor {
        border-bottom: 1px dashed #000;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }

    .campaigDonor:last-child {
        border-bottom: 0px dashed #000;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
}

// Campaign Detail end
// Contact us styling start
label {
    font-size: 16px;
    font-weight: 600;
    color: #222;

    sup {
        color: #EC6768;
    }
}

.contact-us {
    background: url(../public/images/contact.png) right bottom no-repeat;
    padding: 50px 0;

    .addressDetail {
        font-size: 22px;

        .contactAddress {
            background: url(../public/images/icon/contactMapMarker.png) left bottom no-repeat;
            padding: 25px 0 41px 36px;
            margin-top: 25px;
        }

        .contactEmail {
            margin-top: 25px;
            background: url(../public/images/icon/contactEmail.png) left bottom no-repeat;
            padding: 15px 0 15px 36px;
        }
    }
}

// Contact us styling end

// My Profile styling start
.profileImg {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.myProfileContainer {
    margin-top: 80px;

    .profileImgCont {
        border-right: 1px dotted #D8D8D8;
        text-align: center;
        padding-right: 15px;
        height: 100%;
    }    

    .personalInfo {
        .profileInfo {
            font-size: 22px;
            font-weight: 600;
            color: #333;
            border-bottom: 1px dotted #D8D8D8;
            padding-bottom: 10px;
            margin-bottom: 10px;

            span {
                display: block;
                font-weight: 400;
                color: #8C8C8C;
            }
        }
    }

    .profileStats {
        .staticsSub {
            display: flex;
            align-items: center;
            font-size: 22px;
            color: #8C8C8C;
            border-radius: 5px;
            padding: 15px 20px 16px;
            margin-bottom: 20px;

            span {
                font-size: 35px;
                font-weight: 700;
                display: block;
                color: #333;
                line-height: 38px;
            }
        }
    }
}

.recentPayout {
    display: flex;
    border: 1px solid #E3F7FF;
    border-radius: 5px;
    padding: 8px 15px 8px 8px;
    margin-bottom: 15px;
    position: relative;

    .payoutImg {
        margin-right: 15px;
        width: 80px;
        height: 80px;
        border-radius: 5px;
        overflow: hidden;
        display: inline-block;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    .payoutDetails {
        flex-grow: 1;
        font-size: 18px;        

        .payoutDate {
            font-size: 14px;
            display: flex;
            align-items: center;
            opacity: 0.75;

            img {
                margin-right: 5px;
                vertical-align: text-bottom;
                width: 18px;
            }
        }
    }
}

.recentPayout:last-child {
    margin-bottom: 0;
}

.activeSubscription {
    display: flex;
    border: 1px solid #E3F7FF;
    border-radius: 5px;
    padding: 10px 12px;
    margin-bottom: 15px;

    .activeSubscriptionDetails {
        flex-grow: 1;
        font-size: 18px;

        .activeSubscriptionTitle {
            color: #0E8AC0;
            font-weight: 700;
        }
    }
}

.activeSubscription:last-child {
    margin-bottom: 0;
}

// My Profile styling end

// Dashboard styling start
.innnerPageContainer {
    margin-top: 150px;
    ;
}

.userActiveCampaign {
    display: flex;
    border: 1px solid #E3F7FF;
    border-radius: 5px;
    padding: 8px 15px 8px 8px;
    margin-bottom: 15px;
    width: 100%;

    .userCampaignImg {
        margin-right: 15px;
        width: 80px;
        height: 80px;
        border-radius: 5px;
        overflow: hidden;
        display: inline-block;

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }

    .activeCampaignDetails {
        flex-grow: 1;
        font-size: 18px;
        width: calc(100% - 95px);

        .activeCampaignTitle {
            font-weight: 700;
            font-size: 18px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 550px;
        }

        p {
            margin-top: 5px;
            margin-bottom: 0;
            font-size: 16px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 550px;
        }
    }
}

.userActiveCampaign:last-child {
    margin-bottom: 0;
}

// Dashboard styling end
// Donate Form styling start
.donateForm {
    max-width: 500px;
    padding: 0 50px;

    .nav-tabs {
        border: 1px solid #D6EFFB;
        border-radius: 40px;
        padding: 4px 5px 3px;
        display: inline-flex;
        margin: 0 auto;

        .nav-link {
            font-size: 20px;
            color: #333;
            border-radius: 40px;
            padding: 5px 45px;
        }

        .nav-link.active {
            color: #fff;
            background-color: #0E8AC0;
            border-color: #fff;
            font-weight: 500;
        }

        .nav-item {
            margin-bottom: 0;
        }
    }

    .p-button {
        background-color: #D95999;
        text-transform: capitalize;
        border-radius: 5px;
        font-weight: 500;
        color: #fff;
        border: 0;
        padding: 12px 25px;
        font-size: 22px;
        width: 100%;
        justify-content: center;

        &:hover {
            background-color: #b33a76;
        }
    }
}

// Donate Form styling end
// New Request Form styling
.newRequest {
    padding-top: 175px;

    .p-tabview {
        .p-tabview-nav {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin-bottom: 20px;
            border: 0;
            position: relative;
            width: 100%;
            max-width: 750px;
            min-width: auto;
            justify-content: space-between;
            margin: 0 auto;

            &:before,
            &:after {
                height: 1px;
                display: block;
                width: 90%;
                content: '';
                background-color: #D8E6EB;
                position: absolute;
                top: 42px;
                left: 45px;
                z-index: 1;
            }

            &:after {
                background-color: #fff;
                top: 43px;
            }

            li {
                background-color: #F8FDFF;
                position: relative;
                z-index: 2;

                .p-tabview-nav-link {
                    border: 0;
                    padding: 0;
                }
            }

            li.p-highlight .p-tabview-nav-link {
                border: 0;
            }

            li.p-disabled {
                opacity: 1;
            }
        }

        .p-tabview-panels {
            background: none;
            padding: 0;
        }
    }

    .stepwizard-step {
        position: relative;
        z-index: 2;

        p {
            margin: 0px;
            color: #D0D0D0;
            font-size: 19px;
        }
    }

    .btn-step-circle {
        width: 85px;
        height: 85px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 34px;
        font-weight: 700;
        background-color: #E7E7E7;
        color: #fff;
        margin: 0 auto;
        border: 10px solid #F8FDFF;
    }

    .p-highlight {
        .btn-step-circle {
            background-color: #D95999;
        }

        p {
            color: #D95999;
            font-weight: 700;
        }
    }

    .newRequestCont {
        border-radius: 15px;
        overflow: hidden;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
        background-color: #fff;
        margin-top: 75px;
        margin-bottom: 75px;

        .newRequestLeft {
            background: url(../public/images/newRequestBg.jpg) center center no-repeat;
            background-size: cover;
            border-right: 1px solid #0E8AC0;
        }

        .newRequestRight {
            padding: 65px 95px 65px 80px;

            fieldset.scheduler-border {
                border: 1px groove #0E8AC0;
                padding: 0 1.4em 1.4em 1.4em;
                margin: 0 0 1.5em 0;
                border-radius: 5px;
            }

            legend.scheduler-border {
                font-size: 24px;
                font-weight: bold;
                color: #0E8AC0;
                width: auto;
                padding: 0 10px;
            }

            label {
                font-size: 18px;
                font-weight: 600;
            }
        }
    }
}

// New Request Form styling end

// Footer styling start
.footer-area {
    background: #003146;
    padding-top: 60px;
    position: relative;
    float: left;
    width: 100%;
    color: #fff;

    a {
        color: #fff;

        &:hover {
            color: #ADDEF3;
        }
    }

    h3 {
        font-size: 30px;
        margin-bottom: 20px;
    }

    .single-footer-widget ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    .single-footer-widget ul.list li {
        margin-bottom: 9px;
    }

}

p {
    margin: 30px 0 40px;
}

ul.social-list {
    margin-top: 15px;

    li {
        display: inline-block;
        margin-right: 20px;

        a {
            display: inline-block;

            &:hover {
                opacity: 0.75;
            }
        }
    }
}

.copyright-area {
    padding: 30px 15px;
    border-top: 1px dashed #d4d4d4;

    ul {
        text-align: right;
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            display: inline-block;
            position: relative;
            padding-left: 25px;
            margin-right: 10px;

            &:before {
                content: '';
                position: absolute;
                left: 7px;
                top: 5px;
                background: #fff;
                width: 2px;
                height: 15px;
            }
        }
    }
}

.copyright-area ul li:first-child::before {
    display: none;
}

.copyright-area p {
    margin: 0;
}

.pointer {
    cursor: pointer;
}

.error {
    color: red;
    font-size: smaller;
    margin: 1px;
}

.donation-checkbox-label {
    font-weight: 400;
    color: #383838;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
    margin-left: 0px;
}

// Footer styling end
// Edit Profile Popup styling start
input[type='date']{
    position: relative;
    &:after {
        content: '\25BC';
        color: #555;
        padding: 0 5px;
        position: absolute;
        top: 15px;
        right: 10px;
    }
  /* change color of symbol on hover */
    &:hover{
        &:after {
            color: #bf1400;
        }
    }
}

.editProfilePopup {
    max-width: 1024px;
}
// Edit Profile Popup styling end
// Registration Form styling start
.registrationForm {
    max-width: 650px;
    margin: 0 auto;
}
// Registration Form styling end

// Sign Up Section styling start
.signUpSection {
    color: #fff;
    h2 {
        font-size: 35px;
    }
    .signUpSection div h2 {
        color: #D95999;
    }
    .startDonation {
        background: url(../../gc/public/images/counterBg.png) #D95999 repeat;      
    }
    .askForDonation {
        background: url(../../gc/public/images/counterBg.png) #0E8AC0 repeat;
    }
}
// Sign Up Section styling end

// Responsive CSS start
@media (min-width: 1620px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 80%;
    }
}

@media (max-width: 1620px) {
    .newRequest .newRequestCont .newRequestRight {
        padding: 65px 35px 65px 20px;
    }

    .gc_login {
        padding-top: 100px;
        background: none;
    }
}

@media (max-width: 1200px) {
    .navbar .nav-item {
        padding-right: 30px;
    }

    .innerPageBanner {
        height: 400px;
    }

    .browseCampaigns {
        .col-xl-3 {
            padding-bottom: 25px;
        }
    }

    .saveEveryMonth .container-lg>.row {
        padding: 25px 15px;

        .monthlyBenefit {
            padding-right: 0;
        }
    }

    .monthlyDonate .container-lg {
        padding: 15px 20px;
        max-width: 95%;
    }    
}

@media (max-width: 991px) {
    #gcNavbar {
        position: absolute;
        background: #fff;
        width: 100%;
        left: 0;
        top: 66px;
        z-index: 1;
        box-shadow: 0 9px 10px rgba(0, 0, 0, 0.1);
        border-radius: 0 0 10px 10px;
        border-top: 1px solid #E3F7FF;
    }

    .navbar {
        .nav-item {
            padding: 0 30px;
            position: relative;
        }

        .nav-link {
            position: relative;

            &:before {
                height: 2px !important;
                bottom: 0px !important;
            }
        }
    }

    .fact-counter {

        .col-lg-3:nth-child(1),
        .col-lg-3:nth-child(3) {
            .conterItem {
                justify-content: flex-end;

                .pl-4 {
                    width: 235px;
                }
            }
        }
    }

    .newRequest .newRequestCont {
        .newRequestLeft {
            min-height: 55vw;
        }

        .newRequestRight {
            padding: 65px 35px 25px 35px;
        }
    }

    .contact-us {
        background: none;
    }

    .recentPayout .payoutDetails .payoutDate {
        position: absolute;
        right: 16px;
        top: 10px;
    }
 
}

@media (max-width: 767px) {
    .navbar .logo {
        width: 145px;
    }

    .profile-dropdown {
        padding: 3px 0px 5px 0px;
        min-width: 55px;
    }

    .innerPageBanner {
        min-height: 300px;
        background-size: auto 100%;
    }

    .copyright-area,
    .copyright-area ul {
        text-align: center;
    }

    .copyright-area ul {
        padding-top: 10px;
    }

    .banner .banner-cont .banner-text .benner-sub h1 {
        font-size: 35px;
        line-height: 40px;
    }

    .newRequest {
        padding-top: 110px;

        .newRequest .newRequestCont {
            margin: 35px 0;
        }
    }

    .monthlyDonate {
        margin-top: 73px;
    }
    
}

@media (max-width: 575px) {
    .fact-counter {
        padding-bottom: 60px;

        .conterItem {
            padding: 60px 0 0;
            width: 310px;
            margin: 0 auto;
            justify-content: flex-start;
        }

        .col-lg-3:nth-child(1),
        .col-lg-3:nth-child(3) {
            .conterItem {
                justify-content: flex-start;
            }
        }
    }

    .offerSection h2 {
        font-size: 40px;
    }
    
    .recentPayout {
        flex-direction: column;
    }

    .myProfileContainer .profileStats {
        .staticsSub {
            font-size: 16px;
            span {
                font-size: 28px;
            }
        }
    }

    .newRequest .newRequestCont .newRequestRight {
        padding: 65px 15px 25px 15px;
    }
}
// Responsive CSS end 